<template>
  <div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5);; border-radius: 30px;">
			<div style="padding-top: 28px; margin-left: 38px;  margin-right: 38px; margin-bottom:60px" class="d-flex justify-content-between">
				<p class="title">Clientes</p>
				<div class="d-flex">
					<b-form-input
              @keyup="filterByName()"
              id="filterInput"
              size="sm"
              class="mr-sm-2 form-control"
              placeholder="Buscar cliente"
              style="margin-right:30px !important"
            ></b-form-input>
					
					<router-link to="/adicionarCliente">
						<button class="btn-proposta"> 
							Novo cliente
						</button>
					</router-link>
					
				</div>
			</div>
 

    <table class="table table-sm mt-0">
      <thead>
        <tr
        >
          <th scope="col" style="text-align: left; padding-left: 38px">Nome</th>
          <th scope="col">Telefone</th>
          <th scope="col">Email</th>
          <!-- <th scope="col">Status</th> -->
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody class="scrollable">
        <tr v-for="(client, i) in clients" :key="i">
          <td
            style="text-transform: uppercase; vertical-align: middle !important; text-align: left; padding-left: 38px"
          >
            {{ client.nome }}
          </td>
          <td style="vertical-align: middle !important">{{ client.fone }}</td>
          <td style="vertical-align: middle !important">
            {{
              client.email === null ? client.email : client.email.split(";")[0]
            }}
          </td>
          <!-- <td>{{ client.status }}</td> -->

          <!-- <router-link to="/editarCliente"> -->
          <td style="vertical-align: middle !important; line-height: 30px">
            <span
              style="color: #384364; cursor: pointer; font-size: 16px"
              class="material-icons-outlined"
              @click="getId(client.codigo)"
            >
              edit
            </span>
          </td>
          <!-- </router-link> -->
          <!-- <span
              class="material-icons"
              style="cursor: pointer; font-size: 16px"
              @click="deleteClient(client.codigo)"
            >
              delete
            </span> -->
        </tr>
      </tbody>
    </table>

    <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </div>
    </div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"

input.form-control
  display: block
  width: 100%
  height: calc(1.5em + 0.75rem + 2px)
  padding: 0.375rem 0.75rem
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #495057
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.25rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out

.container
  padding: 0px
  margin: 10px
  margin-left: 6%

.btn-proposta
  background: #006992
  border-radius: 10px
  font-family: 'Poppins'
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 24px
  text-align: center
  letter-spacing: -0.01em
  color: #FFFFFF

.table thead th
  border-top: 0px !important
  border-bottom: 0px !important
  color: #B5B7C0
  font-family: Poppins
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: normal
  letter-spacing: -0.14px
  padding: 0.75rem

.table td
  color: #292D32
  font-family: Poppins
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: normal
  letter-spacing: -0.14px
  padding: 0.75rem
</style>

<script >
import ClientService from "../../services/ClientService/ClientService";
import InfiniteLoading from "vue-infinite-loading";
import { getParams } from "../../utils/util";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      clients: [],
      clientService: new ClientService(),
      url: null,
      limit: 20,
      offset: 0,
      filters: {},
    };
  },
  components: {
    InfiniteLoading,
  },

  methods: {
    getId: async function (id) {
      let clientId = id;
      if (clientId) {
        return this.$router.push({ path: `/editarCliente/${clientId}` });
      }
    },

    infiniteHandler($state) {
      let { limit, offset } = this;
      let params = Object.assign({}, { limit, offset }, this.filters);
      this.clientService.list({ params }).then(({ data }) => {
        if (data.next != null) {
          this.clients.push(...data.results);
          this.filters = {};
          ({
            limit: this.limit,
            offset: this.offset,
            ...this.filters
          } = getParams(data.next));

          this.url = data.next;

          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    async deleteClient(id) {
      Swal.fire({
        title: "Certeza que deseja deletar?",
        text: "Essa ação não poderá ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0bb4d4",
        cancelButtonColor: "#A9A9A9",
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          //Fazer cliente sumir da página
          this.clientService.destroy(id).then(({ data }) => {
            
            console.log(data);
          });
          Swal.fire("Deletado!", "Cliente deletado com sucesso", "success");
        }
      });
    },

    filterByName() {
      let filtro = document.getElementById("filterInput").value.toUpperCase();
      if (filtro !== "" || KeyboardEvent.keyCode == 8) {
        this.clientService.list({ params: { search: filtro } }).then((resp) => {
          this.clients = resp.data.results;
        });
      } else {
        this.clientService.list({ params: {} }).then((resp) => {
          this.clients = resp.data.results;
        });
      }
    },
  },
};
</script>
